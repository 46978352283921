<template>
  <v-btn-toggle
    v-model="selectedApt"
    color="primary"
    dense
    group
    :rules="rules.required"
  >
    <v-card
      color="grey lighten-2"
      flat
      tile
      class="d-flex flex-row mb-2 flex-wrap scroll-y"
      max-height="200"
    >
      <v-btn
        v-for="(appointment, index) in appointments"
        :key="index"
        depressed
        width="160"
        :ripple="false"
        :value="appointment"
        :disabled="appointment.booked || appointment.ispast"
        class="no-shadow ma-2 rounded time-slot"
      >
        <span>
          {{ `${appointment.date} ${appointment.startTime}` | formatDate('hh:mm A') }} -
          {{ `${appointment.date} ${appointment.endTime}` | formatDate('hh:mm A') }}
        </span>
      </v-btn>
    </v-card>
  </v-btn-toggle>
</template>

<script>
// Utils
import { rules } from "@/utils/validation";

export default {
  name: "BookingTimeBlock",
  props: {
    appointments: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedApt:undefined,
      rules,
    };
  },
  watch:{
    selectedApt(value){
      const startTime = value ? this.selectedApt.startTime : null
      const endTime = value ? this.selectedApt.endTime : null
      this.$emit('update:start-time', startTime)
      this.$emit('update:end-time', endTime)
      this.$emit('update:show-session-btn', value == undefined ? false : true)
    },
  },
};
</script>
<style lang="scss">
.v-btn.v-btn.v-btn--active {
  background-color: $secondary !important;
  color: $white !important;
  border: none;
}
.v-btn-toggle--group > .v-btn.v-btn {
  margin: 0px !important;
}
.time-slot.v-btn {
  border: 1px dashed #D9D9D9;
  background-color: white !important;
}
.scroll-y{
  overflow-y:auto
}
</style>

